button {
    position: relative;
    border-radius: $radius;
    background-color: $color-primary;
    color: white;
    border: 0px;
    padding: $padding-box-y $padding-box-x;
    font-weight: bold;
    font-size: 14px;
    text-shadow: none;
    transition: all 150ms cubic-bezier(0.19, 1, 0.22, 1);

    .right-arrow {
        padding: 0;
        width: 14px;
        margin-left: 6px;
        margin-bottom: -3px;
        filter: invert(29%) sepia(85%) saturate(616%) hue-rotate(225deg) brightness(93%) contrast(88%);
    }
}

button:hover:before {
    opacity: 0.5;
}

button:hover {
    cursor: pointer;
}

button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background: #e8e8e8;
  color: #464646;
  border: 1px solid #464646;
  outline-width: 0px;
  cursor: not-allowed;
}

button:before {
    content: '';
    position: absolute;
    top: -2px;
    left:-2px;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 5px);
    height: calc(100% + 5px);
    opacity: 0;
    transition: opacity 350ms cubic-bezier(0.19, 1, 0.22, 1);
    border-radius: $radius;
}

.primary-button:before {
    background: linear-gradient(45deg, $color-social-primary, $color-academic-primary, $color-career-primary);
}

.secondary-button:before {
    background: $color-primary;
}

.primary-button {
    color: #8458CA; 
    background: linear-gradient(90deg, #FAE5FF 0%, #FEEFCD 50%, #CFDFF5 100%);
    border: 2px solid #A276E9;
}

.secondary-button {
    color: $color-primary;
    background-color: #F1E8FF;
    border: 2px solid $color-primary;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

@include media(">=sm-desktop", "<desktop") {
    button {
        font-size: 12px;
        padding: 10px 16px;

        .right-arrow {
            width: 12px;
            margin-bottom: -3px;
        }
    }
}