.aboutCard {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 20px;

  img {
    width: 240px;
    height: 240px;
    object-position: center;
    object-fit: cover;
    border-radius: $radius;
    background-color: $gray;
  }

  p {
    margin: 0;
  }

  h3 {
    margin-bottom: 5px;
    margin-top: 10px;
  }

  & > div {
    width: 240px;
    // display: flex;
    // height: 100%;
    // flex-direction: column;
    // justify-content: space-between;
  }
}

.about-card-socials {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 6px;

  a {
    color: $dark-gray;
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.social-email {
  &:hover {
    color: black;
  }
}

.social-linkedin {
  &:hover {
    color: #0077b5;
  }
}

.social-github {
  &:hover {
    color: black;
  }
}

.social-website {
  &:hover {
    color: black;
  }
}

@include media(">=sm-desktop", "<desktop") {
  .aboutCard {  
      img {
        width: 200px;
        height: 200px;
      }
    
      & > div {
        width: 200px;
    }
  }
}

@include media(">=tablet", "<sm-desktop") { 
  .aboutCard {  
    img {
      width: 200px;
      height: 200px;
    }
  
    & > div {
      width: 200px;
    }
  }
}

@include media(">=phone", "<tablet") { 
  .aboutCard {  
    height: 100%;

    img {
      width: 200px;
      height: 200px;
    }
  
    & > div {
      width: 200px;
    }
  }
}

@include media("screen", "<phone") {
  .aboutCard {  
    img {
      width: 150px;
      height: 150px;
    }
  
    & > div {
      width: 150px;
  }
}
}