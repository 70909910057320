$color-primary: #704AAC;
$color-social-primary: #C133E1;
$color-social-secondary: #FAE5FF;
$color-social-light: #fdf3ff;
$color-career-primary: #4E5FEB;
$color-career-secondary: #CFDFF5;
$color-career-light: #dfe7f3;
$color-academic-primary: #ca8f3d;
$color-academic-secondary: #FEEFCD;
$color-academic-light: #fbf5e7;
$color-light-gray: #fafafa;

$color-academic-calendar-selected: #f7c052;
$color-career-calendar-selected: #356EFF;
$color-social-calendar-selected: #d843e5;
$dark-gray: #595959;
$gray: #F9F9F9;

.social { 
    color: $color-social-primary;
}

.career { 
    color: $color-career-primary;
}

.academic { 
    color: $color-academic-primary;
}

.primary {
    background-color: $color-primary;
    outline-color: $color-primary;
}

.black {
    background-color: black;
    outline-color: black;
}

.uw-purple {
    color: #32006e;
}