.tag {
    display: inline-block;
}

.tagText {
    font-size: 1rem;
    border-radius: $radius;
    padding: 4px 16px;
    margin: 0;
}

.social { 
    .tagText {
        background-color: #FAE5FF;
    }
}

.career { 
    .tagText {
        background-color: #CFDFF5;
    }
}

.academic { 
    .tagText {
        background-color: #FEEFCD;
    }
}

.tag-small {
    .tagText {
        font-size: 0.8rem;
        border-radius: $radius;
        padding: 2px 8px;
        margin: 0;
    }
}

@include media(">=sm-desktop", "<desktop") {
    .tagText {
        font-size: 12px;
        border-radius: $radius;
        padding: 6px 12px;
        margin: 0;
    }
}