.eventCard {
    position: relative;
    padding: $padding-content;
    border-radius: $radius;
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    flex: 1;

    & > img {
        width: 110px;
        position: absolute;
        right: -36px;
        top: -50px;
        z-index: 3;
    }

    & > div:first-of-type {
        width: 100%;
        height: 200px;
        border-radius: $radius;
        background-color: black;

        img {
            object-position: center;
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: $radius;
        }
    }
}

.eventCard:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    background-color: white;
}


.eventCardHeader {
    text-align: left;
    margin: 12px 0;

    h1 {
        font-family: $sans-serif;
        font-weight: bold;
        font-size: 1.2rem;
    }

    h1, p {
        margin: 0;
    }
}

.eventCardCategories {
    display: flex;
    gap: 12px;
}

.eventCardBody p {
    width: 330px;
    margin: 12px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@include media(">=sm-desktop", "<desktop") {
    .eventCard {
        position: relative;
        padding: 16px;
    
        & > img {
            width: 90px;
            position: absolute;
            right: -36px;
            top: -50px;
            z-index: 3;
        }
    
        & > div:first-of-type {
            width: 100%;
            height: 170px;
            border-radius: $radius;
            background-color: black;
        }
    }
    
    .eventCard:hover {
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    }
    
    
    .eventCardHeader {
        text-align: left;
        margin: 12px 0;
    
        h1 {
            font-size: 18px;
        }
    
        h1, p {
            margin: 0;
        }

        p {
            font-size: 14px;
        }
    }
    
    .eventCardCategories {
        display: flex;
        gap: 12px;
    }
    
    .eventCardBody p {
        font-size: 14px;
        width: 270px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

@include media("screen", "<tablet") {
    .eventCard {
        scroll-snap-align: center;
        padding: 14px;
    
        & > img {
            width: 60px;
            position: absolute;
            right: -20px;
            top: -30px;
            z-index: 3;
        }
    
        & > div:first-of-type {
            width: 100%;
            height: 170px;
            border-radius: $radius;
            background-color: black;
        }
    }

    .eventCardBody p {
        font-size: 14px;
        width: 270px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}