#rootContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: clip;
}

.baseContainer {
    flex: 1;
    margin: 0 auto;
    width: 1200px;
    min-height: 300px;
    z-index: 1;
}

@include media(">=sm-desktop", "<desktop") {
    .baseContainer {
        width: 1000px;
    }
}

@include media("screen", "<sm-desktop") { 
    .baseContainer {
        width: 100vw;
    }
}