@import 'abstracts/variables';
@import 'abstracts/mixins';
@import 'dist/include-media';

@import 'base/reset';
@import 'base/typography';
@import 'base/colors';
@import 'base/misc';

@import 'abstracts/functions';

@import 'layout/container';
@import 'layout/navigation';
@import 'layout/footer';
@import 'layout/form';

@import 'components/calendar';
@import 'components/buttons';
@import 'components/characterCard';
@import 'components/eventCard';
@import 'components/tag';
@import 'components/resourceCard';
@import 'components/gradientLine';
@import 'components/dropdown';
@import 'components/aboutCard';

@import 'pages/home';
@import 'pages/events';
@import 'pages/resources';
@import 'pages/about';
@import 'pages/electionsfaq';

@import 'addons/toastify';

.constructionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 90vw;
    margin: 0 auto;

    h1 {
        margin: 0;
        font-size: 24px;
    }

    p {
        text-align: center;
        font-size: 16px;
    }

    img {
        margin-top: 24px;
        width: 160px;
    }
}