.calendar {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.calendar-header {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: lighter;
    gap: 24px;

    & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h1 {
            margin: 0;
            font-size: 2.2rem;
            width: 320px;
        }

        p {
            margin: 0;
            font-size: 16px;
        }
    }
}

.calendar-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 60px;
}

.calendar-content-wrapper{
    position: relative;
}

.calendar-tag-wrapper {
    display: flex;
    gap: 8px;
    margin: 0px 0 16px 6px;
    cursor: default;
}

.calendar-item-container {
    position: relative;
    flex-shrink: 0;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.calendar-item {
    background-color: white;
    border-radius: $radius;
    height: 120px;
    width: 105px;
    margin: 6px;
    padding: 12px;
    box-sizing: border-box;

    span {
        user-select: none;
    }
}

.calendar-day-empty {
    border: 2px dashed #AEAEAE;
    background-color: $color-light-gray;
}

.calendar-day-wrapper {
    display: flex;
    flex-direction: column;
    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1);
}

.calendar-day-academic, .calendar-day-career, .calendar-day-social {
    cursor: pointer;

    span {
        display: block;
    }

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    }
}

.calendar-day-academic {
    border: 2px solid $color-academic-primary;
    color: $color-academic-primary;
    background-color: $color-academic-light;
}

.calendar-day-social {
    border: 2px solid $color-social-primary;
    color: $color-social-primary;
    background-color: $color-social-light;
}

.calendar-day-career {
    border: 2px solid $color-career-primary;
    color:  $color-career-primary;
    background-color: $color-career-light;
}

.calendar-day-selected-academic {
    border: 2px solid $color-academic-primary;
    color: white;
    background-color: $color-academic-calendar-selected;
    // box-shadow: rgba(0, 0, 0, 0.45) 0px 5px 15px 0px;
}

.calendar-day-selected-social {
    border: 2px solid $color-social-primary;
    color: white;
    background-color: $color-social-calendar-selected;
    // box-shadow: #c955e3 0px 5px 15px 0px;
}

.calendar-day-selected-career {
    border: 2px solid $color-career-primary;
    color:  white;
    background-color: $color-career-calendar-selected;
    // box-shadow: rgba(0, 0, 0, 0.45) 0px 5px 15px 0px;
}

.calendar-day-none {
    border: 2px solid #AEAEAE;
    color: #AEAEAE;

    .calendar-day-event-name {
        font-weight: normal!important;
        margin-top: auto;
    }
}

.calendar-day-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;

        svg {
            margin-top: -2px;
        }
    }
}

.calendar-rsvp-status {
    font-size: 0.6rem;
    text-align: end;
}

.calendar-day {
    font-size: 12px;
    text-align: end;
    font-weight: bold;
}

.calendar-day-event-name {
    font-weight: bold;
    font-size: 14px;
    margin-top: auto;
}

.calendar-day-organizer-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
}

.calendar-weekday {
    font-size: 12px;
    text-align: center;
    font-weight: bold;
}

.calendar-decoration {
    width: 200px;
    position: absolute;
    left: -140px;
    bottom: 60px;
    z-index: -1;
}

.event-details-container {
    width: 300px;
    height: 512px;
    margin-top: 64px;
    margin-left: $margin-content;
    border-radius: $radius;
    flex-shrink: 0;
    overflow-y: auto;

    border: 2px solid $color-primary;
    background:
        linear-gradient(#ffffff 33%, rgba(255,255,255, 0)),
        linear-gradient(rgba(255,255,255, 0), #ffffff 66%) 0 100%,
        radial-gradient(farthest-side at 50% 0, rgba(79, 79, 79, 0.5), rgba(0,0,0,0)),
        radial-gradient(farthest-side at 50% 100%, rgba(79, 79, 79, 0.5), rgba(0,0,0,0)) 0 100%;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-attachment: local, local, scroll, scroll;
    background-size: 100% 51px, 100% 51px, 100% 17px, 100% 17px;
}

.event-details-wrapper {
    width: 100%;
    padding: 18px;
    box-sizing: border-box;
}

.event-details-header {
    h1 {
        font-weight: bold;
        font-family: $sans-serif;
        font-size: 24px;
    }
}

.event-details-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    img {
        margin: 0 auto;
        width: 100%;
        height: 200px;
        border-radius: $radius;
        object-position: center;
        object-fit: cover;
        border: 1px solid lightgray;
    }

    h1, h2, p {
        margin: 0;
    }

    & > h1 {
        font-size: 1.6rem;
    }

    h2 {
        font-size: 1rem;
        font-family: $sans-serif;
        font-weight: bold;
    }
}

.event-details-body {
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex: 1;

    & > div:last-child {
        margin-top: 12px;
        padding-bottom: 24px;
    }
}

.event-details-section-wrapper {
    position: relative;
    display: flex;
    gap: 18px;
    padding: 12px 0;
    flex-direction: column;

    & > div {
        flex: 1;
        display: flex;
        gap: 8px;
    }

    @include top-gradient-border;
    @include bottom-gradient-border;
}

.event-details-section {
    flex: 1;

    & > p {
        margin-top: 8px;
        font-size: 1rem;
    }
}

.event-details-description {
    white-space: pre-wrap;
    line-height: 22px;
}

.event-details-section-header {
    display: flex;
    align-items: center;
    color: darkgray;

    span {
        margin-top: 3px;
        margin-left: 8px;
        font-size: 0.8rem;
        font-weight: bold;
    }
}

.event-details-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > div {
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: center;

        svg {
            width: 16px;
            height: 16px;
            padding: 6px 0;
        }

        p {
            margin-top: 2px;
            font-size: 14px;
            color: black;
        }
    }
}

.event-details-tags {
    display: flex;
    gap: 6px;
}

.event-details-rsvp {
    position: relative;
    z-index: 1;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 24px 0px;
    border-top: 1px dashed rgb(218, 218, 218);

    h1 {
        margin: 0;
        margin-bottom: 16px;
    }

    button {
        width: 100%;
    }

    input {
        background-color: transparent;
    }
}

.divider {
    border-left: 1px solid lightgray;
}

.event-details-rsvp-form {
    display: flex;
    flex-direction: column;
    flex: 1;

    & > div {
        flex: 1;
    }

    button {
        margin-top: 12px;
    }
}

.rsvp-status {
    padding: 6px 0;
    font-size: 0.8rem;
    font-weight: bold;
    color: #dadada;
}

@include media(">=sm-desktop", "<desktop") {
    .calendar-header {
        & > div {    
            h1 {
                font-size: 28px;
                width: 280px;
            }
    
            p {
                font-size: 12px;
            }
        }
    }

    .calendar-item {
        height: 100px;
        width: 85px;
        margin: 4px;
        padding: 8px;
    }

    .calendar-tag-wrapper {
        left: -4px;
        top: 191px;
        display: flex;
        gap: 8px;
        cursor: default;
    }

    .calendar-decoration {
        width: 200px;
        left: -160px;
        bottom: 26px;
    }

    .calendar-day {
        font-size: 12px;
    }
    
    .calendar-day-event-name {
        font-size: 12px;
    }
    
    .calendar-day-organizer-name {
        font-size: 10px;
    }

    .event-details-container {
        width: 250px;
        height: 420px;
        margin-top: 61px;
        margin-left: 4px;
    }

    .event-details-wrapper {
        padding: 16px;
    }

    .event-details-content {
        gap: 12px;

        img {
            height: 180px;
        }
    }

    .event-details-header {
        h1 {
            font-size: 20px;
        }
    }

    .event-details-body {
        & > div:last-child {
            margin-top: 12px;
            padding-bottom: 12px;
        }
    }

    .event-details-section {
        flex: 1;

        & > p {
            margin-top: 8px;
            font-size: 14px;
        }
    }

    .event-details-section-wrapper {
        margin-top: 6px;
    }

    .event-details-section-header {
        span {
            margin-top: 3px;
            margin-left: 8px;
            font-size: 12px;
        }

        svg {
            width: 12px;
            height: 12px;
        }
    }

    .event-details-info {
        & > div {
            gap: 6px;

            svg {
                width: 14px;
                height: 14px;
                padding: 6px 0;
            }

            p {
                margin-top: 2px;
                font-size: 14px;
                color: black;
            }
        }
    }

    .event-details-tags {
        display: flex;
        gap: 6px;
    }

    .event-details-rsvp {
        padding: 16px 0px;

        h1 {
            margin: 0;
            margin-bottom: 12px;
            font-size: 24px;
        }
    }

    .rsvp-status {
        font-size: 12px;
    }
}
