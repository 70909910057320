.left-arrow {
    width: 24px;
    transform: rotate(180deg);
    padding: 12px;
    cursor: pointer;
}

.right-arrow {
    width: 24px;
    padding: 12px;
    cursor: pointer;
}

.filler {
    display: block;
    flex: 1;
}

@include media(">=sm-desktop", "<desktop") {
    .left-arrow {
        width: 18px;
    }
    
    .right-arrow {
        width: 18px;
    }
}