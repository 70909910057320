nav {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: white;
}

.nav-container {
    position: relative;
    margin: 0 auto;
    background-color: white;
    color: black;
    overflow: hidden;
    align-items: center;
    height: 60px;
    max-width: 1300px;
    display: flex;
    padding: 20px 30px;
    z-index: 100;
    gap: 12px;
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    
    & > span:first-of-type {
        display: block;
        height: 50%;
        border-left: 1px solid black;
    }

    .nav-items-wrapper {
        flex: 1;
        display: flex;
        & > span:last-of-type {
            flex: 1;
        }
    }

    p {
        max-width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.nav-mobile-menu {
    display: none;
}

.nav-scroll {
    height: 40px;
}

.nav-border {
    opacity: 0;
    height: 1px;
    display: block;
    width: 100%;
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    @include bottom-gradient-border;
}

.nav-border-scroll {
    opacity: 1;
}

nav a {
    font-size: 16px;
    color: inherit;
    text-align: center;
    text-decoration: none;
    border-radius: $radius;
    padding: $padding-box-y $padding-box-x;
    border: 2px solid white;
}

.nav-logo {
    padding: 0 12px 0 0;;
}

.nav-items-wrapper a.active {
    position: relative;
    background-color: $gray;

    &::after {
        content: "";
        background: $gradient;
        display: block;
        height: 1px;
        width: 94%;
        position: absolute;
        bottom: 1px;
        left: 0;
        right: 0;
        margin: 0 auto;
        border-radius: $radius;
    }
}

nav a:not(:first-child):hover {
    position: relative;
    background-color: $gray;

    &::after {
        content: "";
        background: $gradient;
        display: block;
        height: 1px;
        width: 94%;
        position: absolute;
        bottom: 1px;
        left: 0;
        right: 0;
        margin: 0 auto;
        border-radius: $radius;
    }
}

nav img {
    display: block;
    width: 60px;
}

.nav-container > div {
    display: flex;
    gap: 18px;
    justify-content: center;
    align-items: center;
}

.nav-button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
}

@include media(">=sm-desktop", "<desktop") {
    .nav-container {
        max-width: 1100px;
        height: 40px;

        p {
            font-size: 14px;
            max-width: 200px;
        }
    }

    .nav-scroll {
        height: 26px;
    }

    nav a {
        font-size: 14px;
        padding: 12px 18px;
    }
}

@include media("screen", "<tablet") {
    .nav-container {
        position: relative;
        align-items: start;
        width: 100%;
        box-sizing: border-box;
        display: block;
        padding: 0;
        flex-direction: column;
        height: fit-content;
        gap: 12px;
        transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
        @include bottom-gradient-border;

        .nav-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            padding: 12px 24px;
            width: 100%;
            border-bottom: 1px solid $gray;
        }

        .nav-items-wrapper {
            width: 100%;
            box-sizing: border-box;
            flex-direction: column;
            padding: 12px 24px;
            gap: 12px;

            & > span:last-of-type {
                display: block;
                width: 100%;
                border-bottom: 1px solid $gray;
            }
        }
    
        & > span:first-of-type {
            display: none;
        }
    
        & > span:last-of-type {
            flex: 1;
        }
    
        p {
            max-width: 300px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

    }

    .nav-items-wrapper a {
        width: 100%;
        font-size: 14px;
        padding: 12px 0;
    }

    .nav-items-wrapper a.active {
        &::after {
            width: 100%;
        }
    }

    nav button {
        width: 100%;
    }

    .nav-button-wrapper {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 0px;
    }

    .nav-mobile-menu {
        display: block;
        @include gradient-border-thin;
        padding: 8px 14px;
        border-radius: $radius;

        svg {
            display: block;
            color: rgb(77, 77, 77);
        }
    }

    .nav-show-items {
        display: block;
    }

    .nav-hide-items {
        display: none!important;
    }
}