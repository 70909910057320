@mixin debug-border {
    border: 1px solid purple;
}

@mixin top-gradient-border {
    &::before {
        content: "";
        background: $gradient;
        display: block;
        height: 1px;
        width: 100%;
        position: absolute;
        top: 0;
    }
}

@mixin bottom-gradient-border {
    &::after {
        content: "";
        background: $gradient;
        display: block;
        height: 1px;
        width: 100%;
        position: absolute;
        bottom: 0;
    }
}

@mixin bottom-gradient-border-thick {
    &::after {
        content: "";
        background: $gradient;
        display: block;
        height: 2px;
        width: 100%;
        position: absolute;
        bottom: 0;
    }
}

@mixin gradient-border {
    background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 6px, transparent 6px) 0% 0%/8px 8px no-repeat,
            radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 6px, transparent 6px) 100% 0%/8px 8px no-repeat,
            radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 6px, transparent 6px) 0% 100%/8px 8px no-repeat,
            radial-gradient(circle at 0 0, #ffffff 0, #ffffff 6px, transparent 6px) 100% 100%/8px 8px no-repeat,
            linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 4px) calc(100% - 16px) no-repeat,
            linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 16px) calc(100% - 4px) no-repeat,
            linear-gradient(90deg, rgba(248,166,255,0.59) 0%, rgba(255,201,63,1) 50%, rgba(207,223,245,1) 100%);
    border-radius: $radius;
    box-sizing: border-box;
}

@mixin gradient-border-thin {
    background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 7px, transparent 7px) 0% 0%/8px 8px no-repeat,
            radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 7px, transparent 7px) 100% 0%/8px 8px no-repeat,
            radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 7px, transparent 7px) 0% 100%/8px 8px no-repeat,
            radial-gradient(circle at 0 0, #ffffff 0, #ffffff 7px, transparent 7px) 100% 100%/8px 8px no-repeat,
            linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 2px) calc(100% - 16px) no-repeat,
            linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 16px) calc(100% - 2px) no-repeat,
            linear-gradient(143deg, rgba(248,166,255,0.59) 0%, rgba(255,201,63,1) 50%, rgba(207,223,245,1) 100%);
    border-radius: 8px;
    padding: 5px;
    box-sizing: border-box;
}