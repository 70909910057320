$sans-serif: 'MyriadPro', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
$serif: 'TTRamillasTrial', Georgia, 'Times New Roman', Times, serif;

body {
    font-family: $sans-serif;
    font-weight: normal;
}

h1 {
    font-family: $serif;
    font-weight: normal;
}

h2 {
    font-family: $serif;
    font-weight: normal;
}

h3 {
    font-family: $sans-serif;
}