$gradient: linear-gradient(226deg, rgba(248,166,255,0.59) 0%, rgba(255,201,63,1) 50%, rgba(207,223,245,1) 100%);

$radius: 8px;

$padding-content: 18px;
$padding-box-x: 18px;
$padding-box-y: 12px;
$margin-content: 18px;

$breakpoints: (phone: 460px, 
               tablet: 768px, 
               sm-desktop: 1024px,
               desktop: 1300px) !default;