.introduction {
    text-align: center;
    width: 60%;
    margin: 42px auto;

    & > h1 {
        margin: 0;
    }

    & > h1:first-of-type {
        font-size: 2.2rem;
    }

    & > h1:last-of-type {
        font-size: 2.2rem;
        margin-bottom: $margin-content;
    }

    & > p {
        margin: 0 auto;
        width: 90%;
        font-size: 1.1rem;
    }
}

.characterCardContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: $margin-content*2;
}

.upcomingEvents {
    margin: 130px 0;

    & > h1 {
        text-align: start;
        font-size: 2.2rem;
    }
    
    h1 {
        margin: 0;
    }

    button {
        display: block;
        margin-left: auto;
        margin-right: 0;
    }
}

.upcomingEventsCardContainer {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: $margin-content 0;
    gap: $margin-content;
    padding: 24px;
    border-radius: $radius;

    span {
        z-index: -5;
    }

    .upcomingEventsBackground:nth-child(1) {
        top: -100px;
        left: -230px;
        width: 350px;
        opacity: 0.2;
        // opacity: 0.05;
        // filter: brightness(0%);
    }

    .upcomingEventsBackground:nth-child(2) {
        top: 330px;
        left: -160px;
        width: 300px;
        opacity: 0.2;
        // opacity: 0.05;
        // filter: brightness(0%);
    }

    .upcomingEventsBackground:nth-child(3) {
        top: 100px;
        right: -180px;
        width: 300px;
        opacity: 0.2;
        // opacity: 0.05;
        // filter: brightness(0%);
    }
}

.upcomingEventsBackground {
    position: absolute;
    z-index: -5;
}

.gallery {
    position: relative;
    width: 100%;

    .galleryHeader h1 {
        text-align: start;
        font-size: 2.2rem;
        margin: 0;
    }

    & > div:last-of-type {
        height: 500px;

        img {
            position: absolute;
            bottom: -20px;
            right: 0;
            z-index: 4;
            width: 350px;
        }
    }
}

.homeSectionHeader {
    position: relative;
    margin-bottom: 82px;
    padding: 12px 0;
    @include bottom-gradient-border-thick;
}

.galleryHeader { 
    margin-bottom: 100px;
}

// .galleryHeader {
//     display: flex;
//     align-items: center;
//     margin-bottom: 76px;
//     padding: 12px;
//     gap: 42px;

//     span {
//         margin: 0 32px;
//         position: relative;
//         display: block;
//         flex: 1;
//         @include bottom-gradient-border-thick;
//     }
// }

.galleryImageContainer {
    position: absolute;
    display: flex;
    align-items: center;
    left: 50%;
    transform: translate(-50%, 0);
    white-space: nowrap;
    text-align: center;

    & > div {
        display: inline-block;
        border-width: 3px;
        border-style: solid;
        border-radius: $radius;
        border-color: black;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    & > div:nth-of-type(1) {
        transform: rotate(5deg);
        width: 240px;
        height: 300px;
        margin-right: -30px;
    }
    & > div:nth-of-type(2) {
        transform: rotate(-15deg);
        width: 280px;
        height: 230px;
        margin-right: -6px;

        img {
            margin-top: -70px;
        }
    }
    & > div:nth-of-type(3) {
        transform: rotate(-5deg);
        width: 240px;
        height: 320px;
    }
    & > div:nth-of-type(4) {
        transform: rotate(5deg);
        width: 220px;
        height: 300px;
        z-index: 2;
        margin-left: -10px;
        margin-bottom: -50px;
    }
    & > div:nth-of-type(5) {
        transform: rotate(-5deg);
        width: 340px;
        height: 280px;
        z-index: 1;
        margin-left: -14px;
        margin-top: -40px;

        img {
            width: 500px;
            margin-left: -110px;
        }
    }
    & > div:nth-of-type(6) {
        transform: rotate(10deg);
        width: 300px;
        height: 300px;
        margin-left: -28px;
        margin-bottom: -20px;
        z-index: 3;
    }
    & > div:nth-of-type(7) {
        transform: rotate(-10deg);
        width: 300px;
        height: 260px;
        margin-top: -80px;
        margin-left: -26px;
        z-index: 4;
    }
    & > div:nth-of-type(8) {
        transform: rotate(5deg);
        width: 400px;
        height: 280px;
        margin-left: -20px;
    }
}

.characterBackground1 {
    z-index: -1;

    img {
        position: absolute;
    }

    img:nth-of-type(1) {
        width: 16px;
        left: 20px;
        opacity: 0.1;
        left: -150px;
        top: 30px;
    }
    img:nth-of-type(2) {
        width: 40px;
        top: 40px;
        left: -30px;
        opacity: 0.5;
    }
    img:nth-of-type(3) {
        width: 34px;
        top: 80px;
        left: -180px;
        opacity: 0.1;
    }
}

.characterBackground2 {
    z-index: -1;

    img {
        position: absolute;
    }

    img:nth-of-type(1) {
        width: 26px;
        left: -40px;
        top: -30px;
        opacity: 0.5;
    }
    img:nth-of-type(2) {
        width: 26px;
        left: -90px;
        top: 50px;
        opacity: 0.6;
    }
    img:nth-of-type(3) {
        width: 26px;
        left: -50px;
        top: 130px;
        opacity: 0.5;
    }
}

.characterBackground3 {
    z-index: -1;

    img {
        position: absolute;
    }

    img:nth-of-type(1) {
        width: 22px;
        left: -120px;
        top: 100px;
        opacity: 0.3;
    }

    img:nth-of-type(2) {
        width: 22px;
        left: -100px;
        top: 150px;
        opacity: 0.2;
    }
    img:nth-of-type(3) {
        width: 22px;
        left: -100px;
        opacity: 1;
        opacity: 0.2;
    }
}

.characterBackgroundRight {
    transform: scaleX(-1);
}

.backgroundIcons {
    position: absolute;

    img {
        position: absolute;
        width: 10px;
        height: 10px;
        opacity: 0.2;
        z-index: -2;
    }

    $elements: 500;
    @for $i from 0 to $elements {
        img:nth-child(#{$i + 1}) {
            top: random($limit: 300) * 1px;
            left: random($limit: 500) * 1px;
        }
    }
}

@include media(">=sm-desktop", "<desktop") {
    .introduction {
        width: 80%;
        margin-top: 24px;

        & > h1 {
            margin: 0;
        }
    
        & > h1:first-of-type {
            font-size: 32px;
        }
    
        & > h1:last-of-type {
            font-size: 32px;
        }
    
        & > p {
            width: 70%;
            font-size: 14px;
        }
    }
    
    .characterCardContainer {
        margin-top: 0;
        margin: 0 auto;
        transform: scale(0.8);
        width: 710px;
    }
    
    .upcomingEvents {
        margin: 90px 0;
        
        & > h1 {
            font-size: 32px;
            margin: 0;
        }
    
        button {
            display: block;
            margin-left: auto;
            margin-right: 0;
        }
    }

    .upcomingEventsCardContainer {
        padding: 18px;
    
        .upcomingEventsBackground:nth-child(1) {
            top: -140px;
            left: -240px;
            width: 350px;
        }
    
        .upcomingEventsBackground:nth-child(2) {
            top: 280px;
            left: -160px;
            width: 300px;
        }
    
        .upcomingEventsBackground:nth-child(3) {
            top: 100px;
            right: -180px;
            width: 300px;
        }
    }
    
    .gallery {    
        h1 {
            font-size: 32px;
            margin-bottom: 32px;
        }
    
        & > div:last-of-type {
            height: 420px;
    
            img {
                width: 300px;
            }
        }
    }

    .galleryHeader {
        margin-bottom: 58px;
    }
    
    .galleryImageContainer {
        transform: translate(-50%, 0) scale(0.8);
    }
}

@include media(">=tablet", "<sm-desktop") { 
    .introduction {
        text-align: center;
        width: 100%;
        margin: 42px auto;
    
        & > h1 {
            margin: 0;
        }
    
        & > h1:first-of-type {
            font-size: 2.2rem;
        }
    
        & > h1:last-of-type {
            font-size: 2.2rem;
            margin-bottom: $margin-content;
        }
    
        & > p {
            margin: 0 auto;
            width: 90%;
            font-size: 1.1rem;
        }
    }
    
    .characterCardScrollContainer {
        overflow: none;
        display: flex;
        padding: 0 60px;
        padding-bottom: 70px;
        justify-content: center;
    }

    .characterCardContainer {
        display: flex;
        justify-content: center;
        gap: 24px;
    }
    
    .upcomingEvents {
        margin: 36px auto;
    
        & > h1 {
            text-align: start;
            font-size: 2.2rem;
        }
        
        h1 {
            margin: 0;
        }
    
        button {
            display: block;
            margin-left: auto;
            margin-right: 0;
        }
    }
    
    .upcomingEventsCardContainer {
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        margin: $margin-content 0;
        gap: $margin-content;
        padding-top: 60px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .upcomingEventsButtonContainer {
        width: 90vw;
        margin: 0 auto;
    }
    
    .gallery {
        & > div:last-of-type {
            height: 70px;
    
            img {
                bottom: -20px;
                right: 10px;
                width: 250px;
            }
        }
    }
    
    .homeSectionHeader {
        position: relative;
        width: 90vw;
        margin: 24px auto;
        padding: 12px 0;
        @include bottom-gradient-border-thick;
    }
    
    .galleryHeader { 
        margin-bottom: 0px;
    }
    
    .galleryImageContainer {
        position: relative;
        display: block;
        left: 0;
        transform: none;
        overflow-x: scroll;
        padding: 60px 60px;
    
        & > div {
            overflow: hidden;
            display: inline-block;
            border-width: 3px;
            border-style: solid;
            border-radius: $radius;
            border-color: black;
    
            img {
                width: 100%;
            }
        }
    
        & > div:nth-of-type(1) {
            transform: rotate(5deg);
            width: 240px;
            height: 300px;
            margin-right: -30px;
        }
        & > div:nth-of-type(2) {
            transform: rotate(-15deg);
            width: 280px;
            height: 230px;
            margin-right: -6px;
    
            img {
                margin-top: -70px;
            }
        }
        & > div:nth-of-type(3) {
            transform: rotate(-5deg);
            width: 240px;
            height: 320px;
        }
        & > div:nth-of-type(4) {
            transform: rotate(5deg);
            width: 220px;
            height: 300px;
            z-index: 2;
            margin-left: -10px;
            margin-bottom: -50px;
        }
        & > div:nth-of-type(5) {
            transform: rotate(-5deg);
            width: 340px;
            height: 280px;
            z-index: 1;
            margin-left: -14px;
            margin-top: -40px;
    
            img {
                width: 500px;
                margin-left: -110px;
            }
        }
        & > div:nth-of-type(6) {
            transform: rotate(10deg);
            width: 300px;
            height: 300px;
            margin-left: -28px;
            margin-bottom: -20px;
            z-index: 3;
        }
        & > div:nth-of-type(7) {
            transform: rotate(-10deg);
            width: 300px;
            height: 260px;
            margin-top: -80px;
            margin-left: -26px;
            z-index: 4;
        }
        & > div:nth-of-type(8) {
            transform: rotate(5deg);
            width: 400px;
            height: 280px;
            margin-left: -20px;
        }
    }
}

@include media("screen", "<tablet") {
    .introduction {
        text-align: center;
        width: 100%;
        margin: 42px auto;
    
        & > h1 {
            margin: 0;
        }
    
        & > h1:first-of-type {
            font-size: 2.2rem;
        }
    
        & > h1:last-of-type {
            font-size: 2.2rem;
            margin-bottom: $margin-content;
        }
    
        & > p {
            margin: 0 auto;
            width: 90%;
            font-size: 1.1rem;
        }
    }
    
    .characterCardScrollContainer {
        overflow-x: auto;
        display: flex;
        scroll-snap-type: x mandatory;
        padding: 0 60px;
        padding-bottom: 70px;
    }

    .characterCardContainer {
        display: flex;
        justify-content: center;
        gap: 24px;
    }
    
    .upcomingEvents {
        margin: 36px auto;
    
        & > h1 {
            text-align: start;
            font-size: 2.2rem;
        }
        
        h1 {
            margin: 0;
        }
    
        button {
            display: block;
            margin-left: auto;
            margin-right: 0;
        }
    }
    
    .upcomingEventsCardContainer {
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        margin: $margin-content 0;
        gap: $margin-content;
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .upcomingEventsButtonContainer {
        width: 90vw;
        margin: 0 auto;
    }
    
    .gallery {
        & > div:last-of-type {
            height: 70px;
    
            img {
                bottom: -20px;
                right: 10px;
                width: 250px;
            }
        }
    }
    
    .homeSectionHeader {
        position: relative;
        width: 90vw;
        margin: 24px auto;
        padding: 12px 0;
        @include bottom-gradient-border-thick;
    }
    
    .galleryHeader { 
        margin-bottom: 0px;
    }
    
    .galleryImageContainer {
        position: relative;
        display: block;
        left: 0;
        transform: none;
        overflow-x: scroll;
        padding: 60px 60px;
    
        & > div {
            overflow: hidden;
            display: inline-block;
            border-width: 3px;
            border-style: solid;
            border-radius: $radius;
            border-color: black;
    
            img {
                width: 100%;
            }
        }
    
        & > div:nth-of-type(1) {
            transform: rotate(5deg);
            width: 240px;
            height: 300px;
            margin-right: -30px;
        }
        & > div:nth-of-type(2) {
            transform: rotate(-15deg);
            width: 280px;
            height: 230px;
            margin-right: -6px;
    
            img {
                margin-top: -70px;
            }
        }
        & > div:nth-of-type(3) {
            transform: rotate(-5deg);
            width: 240px;
            height: 320px;
        }
        & > div:nth-of-type(4) {
            transform: rotate(5deg);
            width: 220px;
            height: 300px;
            z-index: 2;
            margin-left: -10px;
            margin-bottom: -50px;
        }
        & > div:nth-of-type(5) {
            transform: rotate(-5deg);
            width: 340px;
            height: 280px;
            z-index: 1;
            margin-left: -14px;
            margin-top: -40px;
    
            img {
                width: 500px;
                margin-left: -110px;
            }
        }
        & > div:nth-of-type(6) {
            transform: rotate(10deg);
            width: 300px;
            height: 300px;
            margin-left: -28px;
            margin-bottom: -20px;
            z-index: 3;
        }
        & > div:nth-of-type(7) {
            transform: rotate(-10deg);
            width: 300px;
            height: 260px;
            margin-top: -80px;
            margin-left: -26px;
            z-index: 4;
        }
        & > div:nth-of-type(8) {
            transform: rotate(5deg);
            width: 400px;
            height: 280px;
            margin-left: -20px;
        }
    }
}