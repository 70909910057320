.form-input {
    box-sizing: border-box;
    width: 100%;
    padding: 13px 22px;
    border-radius: 5px;
    border: 1px solid #dde3ec;
    background: #ffffff;
    font-size: 14px;
    color: #536387;
    outline: none;
    resize: none;
    margin-bottom: 24px;
}

.form-input:focus {
    border-color: $color-primary;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.form-label {
    color: #07074d;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    display: block;
    margin-bottom: 6px;
}

input:disabled {
    background-color: #f4f4f4!important;
    cursor: not-allowed;
}

@include media(">=sm-desktop", "<desktop") {
    .form-input {
        padding: 10px 16px;
        font-size: 12px;
        margin-bottom: 12px;
    }

    .form-label {
        font-size: 12px;
        margin-bottom: 4px;
    }
}