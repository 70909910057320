.resourceContainer {
  width: 1000px;
  margin: 0 auto;
  position: relative;
}

.resourceHeader {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 60px;
  gap: 42px;
}

.resourceImgContainer {
  flex: 1;
  display: flex;
  align-items: center;
}

.resourceImg {
  height: 160px;
}

.resourceSummary {
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 200px;

  h1 {
    font-size: 2.2rem;
    margin: 0;
  }
}

.resourceContentContainer {
  display: flex;
  box-sizing: border-box;
  gap: 42px;
}

.resourceCategoryHeader  {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @include bottom-gradient-border-thick;

  h2 {
    margin: 24px 0;
  }
}

.resourcesWrapper {
  flex: 4;
  margin-bottom: 60px;
}

/* Resources section (where the components will go) styling */
.legendContainer {
  flex: 1;
  height: 3700px;
  margin-top: 0px;
}

.legend {
  position: sticky;
  top: 170px;
  display: flex;
  flex-direction: column;

  .legendHeader {
    text-transform: uppercase;
    font-family: $serif;
    font-weight: bold;
    z-index: 2;
    padding-top: 10px;
  }

  ul {
    margin: 0;
    padding: 0 0 0 1.25rem;
    z-index: 2;
  }

  li {
    font-size: 18px;
    padding: 6px 0;
    cursor: pointer;

    &:hover {
      font-weight: bold;
    }
  }
}

/* Blobs styling, in chronological order */
.blob1 {
  background: radial-gradient(ellipse at center, #F8A6FF 0%, #FAE5FF 40%, rgba(109, 37, 100, 0) 70%);
  position: absolute;
  width: 500px;
  left: -110px;
  height: 300px;
  top: -30px;
  z-index: -1;
}

.blob2 {
  background: radial-gradient(ellipse at center, #fae8bc 0%, #fff4db 40%, rgba(109, 37, 100, 0) 70%);
  position: absolute;
  width: 700px;
  left: 150px;
  height: 300px;
  top: -30px;
  z-index: -1;
}

.blob3 {
  background: radial-gradient(ellipse at center, #d8e4f5 0%, rgba(109, 37, 100, 0) 65%);
  position: absolute;
  width: 300px;
  height: 300px;
  left: -100px;
  top: 100px;
  z-index: -1;
}

@include media(">=sm-desktop", "<desktop") {
  .resourceContainer {
    width: 900px;
  }

  .resourceImg {
    height: 140px;
    margin-bottom: 40px;
  }

  .resourceHeader {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .resourceSummary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 170px;

    h1 {
      font-size: 32px;
      margin: 0;
    }
  }

  .resourcesWrapper {
    margin-bottom: 60px;
  }

  /* Resources section (where the components will go) styling */
  .legendContainer {
    height: 3700px;
    margin-top: 2px;
  }

  .legend {
    top: 80px;

    h3 {
      font-size: 16px;
    }

    li {
      font-size: 14px;
    }
  }

  .resourceBoxContainer {
    h2 {
      font-size: 24px;
    }
  }

  /* Blobs styling, in chronological order */
  .blob1 {
    background: radial-gradient(ellipse at center, #f8a6ffc3 0%, #FAE5FF 40%, rgba(109, 37, 100, 0) 70%);
    position: absolute;
    width: 300px;
    left: -80px;
    height: 250px;
    top: -50px;
    z-index: -1;
  }

  .blob2 {
    background: radial-gradient(ellipse at center, #fae8bcd1 0%, #fff4db 40%, rgba(109, 37, 100, 0) 70%);
    position: absolute;
    width: 500px;
    left: 100px;
    height: 250px;
    top: -50px;
    z-index: -1;
  }

  .blob3 {
    background: radial-gradient(ellipse at center, #d8e4f5c4 0%, rgba(109, 37, 100, 0) 65%);
    position: absolute;
    width: 250px;
    height: 250px;
    left: -100px;
    top: 70px;
    z-index: -1;
  }
}

@include media("screen", "<sm-desktop") {
  .resourceContainer {
    width: 90vw;
  }

  .resourceImg {
    display: none;
    height: 100px;
    margin-bottom: 40px;
  }

  .resourceHeader {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 30px;
  }

  .resourceSummary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 170px;

    h1 {
      font-size: 32px;
      margin: 0;
    }
  }

  .resourcesWrapper {
    margin-bottom: 60px;
  }

  /* Resources section (where the components will go) styling */
  .legendContainer {
    display: none;
  }

  .resourceBoxContainer {
    h2 {
      font-size: 24px;
    }
  }

  /* Blobs styling, in chronological order */
  .blob1 {
    display: none;
  }

  .blob2 {
    display: none;
  }

  .blob3 {
    display: none;
  }
}